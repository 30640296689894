<template>
  <div class="modal-content modal_content">
    <a-form-model
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="ruleForm"
      :rules="rules"
    >
      <a-form-model-item
        v-for="item in formOption"
        :key="item.value"
        :label="item.label"
        :ref="item.value"
        :prop="item.value"
      >
        <!-- 输入 -->
        <a-input
          v-if="item.type === 'input'"
          v-model="form[item.value]"
          :placeholder="'请输入' + item.label"
        ></a-input>
        <!-- 搜索  @change="change()"-->
        <a-select
          v-if="item.type === 'select'"
          v-model="form[item.value]"
          :placeholder="'请选择' + item.label"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!-- 远程搜索 -->
        <a-select
          v-if="item.type === 'remoteSearch'"
          show-search
          v-model="form[item.value]"
          :placeholder="'请输入查询' + item.label"
          :default-active-first-option="false"
          :show-arrow="true"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch($event, item.method, item.value)"
          @change="handleChange"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div style="margin-top: 20px; text-align: right">
      <a-button @click="cancel()">返回</a-button>
      <a-button @click="submit()" type="primary" style="margin-left: 16px"
        >确认</a-button
      >
    </div>
  </div>
</template>

<script>
import { Addcompany, Updatecompany } from "@/api/role";

const formOption = [
  {
    label: "分公司名称",
    value: "bc_name",
    type: "input",
  },
  {
    label: "分公司编号",
    value: "bc_only",
    type: "input",
  },
  {
    label: "行业类型",
    value: "bc_type",
    type: "select",
    options: [
      {
        value: "1",
        label: "装修公司",
      },
      {
        value: "2",
        label: "供应商",
      },
    ],
  },
];
export default {
  name: "changeCompany",
  props: {
    formType: {
      type: String,
      default: "add",
    },
    record: {
      type: Object,
    },
  },
  data() {
    return {
      formOption: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      addVerify: ["bc_name", "bc_only", "bc_type"],
      editVerify: ["bc_id", "bc_name", "bc_only", "bc_type"],
      rules: {
        bc_name: [
          {
            required: true,
            message: "请输入分公司名称",
            trigger: "blur",
          },
          // {
          //   min: 2,
          //   max: 5,
          //   message: "Length should be 3 to 5",
          //   trigger: "blur",
          // },
        ],
        bc_only: [
          {
            required: true,
            message: "请输入分公司编号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // console.log(this.formType, this.record);
    this.formOption = formOption;
    // console.log(this.formOption);

    // this.fetchCompany();
    if (this.formType === "edit") {
      // console.log("edit", this.record);
      this.form = JSON.parse(JSON.stringify(this.record));
    }
  },
  methods: {
    handleChange(el) {},
    handleSearch(val, method, key) {},
    cancel() {
      this.$emit("onCloseComp");
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let req = null;
          if (this.formType === "add") {
            const orderParam = this.$order(this.form, this.addVerify);
            const params = {
              ...orderParam,
              verify: this.$verify(orderParam, this.addVerify),
            };
            req = Addcompany(params);
          } else {
            const orderParam = this.$order(this.form, this.editVerify);
            const params = {
              ...orderParam,
              verify: this.$verify(orderParam, this.editVerify),
            };
            console.log("upd-params", params);
            req = Updatecompany(params);
          }
          // this.$emit("onCloseComp");
          req.then((res) => {
            if (res.msg == "failed") {
              this.$message.error(res.code);
            } else {
              this.$message.success(res.data);
              this.$emit("onCloseComp");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style  lang="less" scoped>
</style>