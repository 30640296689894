<template>
  <div style="padding-top: 64px">
    <div class="table-button left">
      <a-button
        icon="plus"
        v-show="isShowBtns.indexOf('Organization-/api/company/addcompany') > -1"
        @click="changeCompany('add', '添加分公司')"
        >添加分公司</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="bc_name"
      :columns="columns"
      :data="loadData"
      :showPagination="false"
      :pagination="false"
    >
      <span slot="bc_type" slot-scope="text">
        {{ text == 1 ? "装修公司" : text == 2 ? "供应商" : "" }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          v-show="
            record.type == '门店' &&
            isShowBtns.indexOf(
              'Organization-/api/companystore/updatecompanystore'
            ) > -1
          "
          @click="openSubpage('edit', '编辑', record)"
          >编辑
        </a>
        <a-popconfirm
          title="是否确定删除门店信息?"
          ok-text="删除"
          cancel-text="取消"
          @confirm="confirm"
          @cancel="cancel"
          v-show="record.type == '门店'"
        >
          <a
            class="deletStyle"
            v-if="
              isShowBtns.indexOf(
                'Organization-/api/companystore/deletecompanystore'
              ) > -1
            "
            @click="openSubpageDelet('delet', '删除', record)"
            >删除
          </a>
        </a-popconfirm>

        <a
          v-show="
            record.type == '分公司' &&
            isShowBtns.indexOf(
              'Organization-/api/companystore/addcompanystore'
            ) > -1
          "
          @click="openSubpage('add', '添加子级', record)"
          >添加子级</a
        >
        <a
          v-show="
            record.type == '分公司' &&
            isShowBtns.indexOf('Organization-/api/company/updatecompany') > -1
          "
          class="compName"
          @click="changeCompany('edit', '修改分公司', record)"
          >修改</a
        >
        <a-popconfirm
          title="是否确定删除分公司信息?"
          ok-text="删除"
          cancel-text="取消"
          @confirm="() => onDeleteComp(record)"
          @cancel="cancelComp"
        >
          <a
            class="deletStyle"
            v-if="
              record.type == '分公司' &&
              isShowBtns.indexOf(
                'Organization-/api/companystore/deletecompanystore'
              ) > -1
            "
            >删除
          </a>
        </a-popconfirm>
      </span>
    </s-table>
    <!-- <div style="height: 16px"></div> -->
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :width="750"
      :closable="true"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <div v-if="edit" class="editName">
        <a-form-model
          ref="ruleForm"
          :model="formEdit"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @validate="validateValue"
        >
          <!-- 下拉框 父级组织-->

          <a-form-model-item label="父级组织" prop="region" class="select">
            <a-select v-model="formEdit.region">
              <a-select-option
                v-for="item in CompanystoreList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 单选按钮 组织类型  -->
          <a-form-model-item label="组织类型" prop="resource">
            <!-- <a-radio-group v-model="formEdit.resource">
              <a-radio value="1"> 公司 </a-radio>
              <a-radio value="2"> 门店 </a-radio>
            </a-radio-group> -->
            <span> {{ record.type }}</span>
          </a-form-model-item>
          <!-- 输入框 组织名称-->
          <a-form-model-item label="门店名称" prop="desc">
            <a-input v-model="formEdit.desc" />
          </a-form-model-item>
          <!-- 输入框 负责人姓名-->
          <a-form-model-item label="负责人姓名" prop="descPeople">
            <a-input v-model="formEdit.descPeople" />
          </a-form-model-item>
          <!-- 输入框 负责人手机号-->
          <a-form-model-item label="负责人手机号" prop="descPhone">
            <a-input v-model="formEdit.descPhone" />
          </a-form-model-item>
          <!-- 提交按钮 -->
          <a-form-model-item :wrapper-col="{ span: 14, offset: 20 }">
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
            <a-button style="margin-left: 10px" @click="resetForm">
              重置
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- <div v-else>添加子集</div> -->
    </a-drawer>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :width="750"
      :closable="true"
      :visible="visibleComp"
      :destroyOnClose="true"
      @close="onCloseComp"
    >
      <changeCompany
        :record="recordComp"
        :formType="formType"
        @onCloseComp="onCloseComp"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable } from "@/components";
import {
  GetOrderList,
  UpdateCompanystore,
  Addcompanystore,
  DeleteCompanystore,
  Delcompany,
} from "@/api/role";
import { GetCompaniesStore } from "@/api/device";
import changeCompany from "./changeCompany.vue";

const columns = [
  {
    title: "组织名称",
    dataIndex: "bc_name",
  },
  {
    title: "行业类型",
    dataIndex: "bc_type",
    scopedSlots: { customRender: "bc_type" },
  },
  {
    title: "组织类型",
    dataIndex: "type",
  },
  {
    title: "组织负责人",
    dataIndex: "cs_bossname",
    scopedSlots: { customRender: "cs_bossname" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "160px",
    scopedSlots: { customRender: "action" },
  },
];

const orderParam = ["bc_id", "bc_name", "cs_data"];
export default {
  name: "Organization",
  components: {
    STable,
    changeCompany,
  },
  data() {
    this.columns = columns;
    return {
      // 查询参数
      queryParam: {},
      CompanystoreList: [], //设备分公司名称
      // 弹出框
      visible: false,
      visibleComp: false,
      edit: false,
      // visible: true,
      // edit: true,
      DrawerTitle: "",

      // 编辑
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      other: "",
      formEdit: {
        // name: "",
        region: "", //父级组织
        resource: "2", //组织类型
        desc: "", //门店名称
        descPeople: "", //负责人
        descPhone: "", //负责人手机号
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
          {
            min: 1,
            // max: 5,
            message: "Length should be 1 ",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择父级组织",
            trigger: "change",
          },
        ],

        type: [
          {
            type: "array",
            required: true,
            message: "Please select at least one activity type",
            trigger: "change",
          },
        ],
        resource: [{}], //组织类型
        desc: [
          {
            required: true,
            message: "门店名称不能为空",
            trigger: "blur",
          },
        ],
        descPeople: [
          {
            // required: true,
            message: "",
            trigger: "blur",
          },
          {
            min: 2,
            // max: 5,
            message: "Length should be 1 ",
            trigger: "blur",
          },
        ],
        descPhone: [
          {
            // required: true,
            message: "",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "Length should be 11 ",
            trigger: "blur",
          },
        ],
      },
      record: {},
      drawerType: "",
      deletRecord: [],
      isShowBtns: [],
      recordComp: {},
      formType: "",
    };
  },
  created() {
    this.GetCompaniesStore();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {
    formEdit(NValue) {
      console.log(NValue);
    },
  },
  computed: {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetOrderList(params).then((res) => {
        let data = res.data;
        // console.log(data);
        let valueList = [];
        data.forEach((item) => {
          let children = [];
          if (item.cs_data.length > 0) {
            item.cs_data.forEach((el) => {
              children.push({
                bc_name: el.cs_name,
                cs_id: el.cs_id,
                cs_bossname: el.cs_bossname,
                type: "门店",
                cs_bossphone: el.cs_bossphone,
                bc_only: el.bc_only,
                bc_type: el.bc_type,
              });
            });
            valueList.push({
              bc_name: item.bc_name,
              bc_id: item.bc_id,
              type: "分公司",
              children: children,
              bc_only: item.bc_only,
              bc_type: item.bc_type,
            });
          } else {
            valueList.push({
              bc_name: item.bc_name,
              bc_id: item.bc_id,
              type: "分公司",
              bc_only: item.bc_only,
              bc_type: item.bc_type,
              // children: [],
            });
          }
        });
        // console.log(valueList);
        // console.log(res);
        let resData = {};
        resData.data = valueList;
        return resData;
      });
    },

    // 获取设备分公司名称
    GetCompaniesStore() {
      let order = ["bc_id", "bc_name", "cs_data"];
      const param = {
        bc_id: "",
        bc_name: "",
        cs_data: "",
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      GetCompaniesStore(params).then((res) => {
        // console.log(res.data);
        // this.filters.CompanystoreList.length = 0;
        this.CompanystoreList = [];
        this.CompanystoreList.push({
          value: "",
          label: "全部",
          // cs_data: [],
        });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.CompanystoreList.push({
            value: element.bc_id,
            label: element.bc_name,
            // cs_data: element.cs_data,
          });
        }
      });
    },
    // 操作
    openSubpage(type, name, record) {
      console.log(type, name, record);
      this.drawerType = type;
      this.record = record;
      this.visible = true;
      this.DrawerTitle = name;
      this.edit = true;
      if (type == "edit") {
        this.formEdit.region = record.bc_id;
        this.formEdit.desc = record.bc_name;
        this.formEdit.descPeople = record.cs_bossname;
        this.formEdit.descPhone = record.cs_bossphone;
        // this.
      } else {
        this.formEdit.region = record.bc_id;
        // this.edit = false;
        this.formEdit.desc = "";
        this.formEdit.descPeople = "";
        this.formEdit.descPhone = "";
      }
    },
    // 删除
    openSubpageDelet(type, name, record) {
      this.deletRecord = record;
    },
    // 弹出框
    afterVisibleChange(val) {},
    afterVisibleChangeComp(val) {},
    onClose() {
      this.visible = false;
    },
    onCloseComp() {
      this.visibleComp = false;
      this.$refs.table.refresh();
    },
    // 提交编辑
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      console.log("submit!", this.formEdit);
      //编辑
      if (this.drawerType == "edit") {
        let order = [
          "cs_id",
          "bc_id",
          "cs_name",
          "cs_bossname",
          "cs_bossphone",
        ];
        const param = {
          bc_id: this.formEdit.region,
          cs_id: this.record.cs_id,
          cs_name: this.formEdit.desc,
          cs_bossname: this.formEdit.descPeople,
          cs_bossphone: this.formEdit.descPhone,
        };
        const params = {
          ...param,
          verify: this.$verify(param, order),
        };

        UpdateCompanystore(params).then((res) => {
          this.$message.success(res.data);
          // 刷新表格
          // this.imeiList = [];
          this.onClose();
          this.$refs.table.refresh();
        });
      } else if (this.drawerType == "add") {
        let order = ["bc_id", "cs_name", "cs_bossname", "cs_bossphone"];
        const param = {
          bc_id: this.formEdit.region,
          cs_name: this.formEdit.desc,
          cs_bossname: this.formEdit.descPeople,
          cs_bossphone: this.formEdit.descPhone,
        };
        const params = {
          ...param,
          verify: this.$verify(param, order),
        };

        Addcompanystore(params).then((res) => {
          this.$message.success(res.data);
          // 刷新表格
          // this.imeiList = [];
          this.onClose();
          this.$refs.table.refresh();
        });
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 表单被检验触发
    validateValue() {
      // console.log(this.formEdit);
    },
    confirm(e) {
      // console.log(e);
      // this.$message.success("删除成功");
      let order = ["cs_id"];
      const param = {
        cs_id: this.deletRecord.cs_id,
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };

      DeleteCompanystore(params).then((res) => {
        this.$message.success(res.data);
        // 刷新表格
        this.$refs.table.refresh();
      });
    },
    cancel(e) {
      console.log(e);
      this.$message.error("取消删除");
    },
    // 删除分公司信息
    onDeleteComp(e) {
      console.log("确定删除", e);
      const param = {
        bc_id: e.bc_id,
      };
      const orderParam = ["bc_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return Delcompany(params).then((res) => {
        if (res.msg == "failed") {
          this.$message.error(res.code);
        } else {
          this.$message.success(res.data);
          // this.$emit("onCloseComp");
        }
        this.$refs.table.refresh();
      });
    },
    cancelComp(e) {
      this.$message.error("取消删除");
    },
    // 新增 修改 分公司按钮
    changeCompany(type, name, record) {
      this.DrawerTitle = name;
      console.log("changeCompany", record);
      this.visibleComp = true;
      this.recordComp = record;
      this.formType = type;
    },
  },
};
</script>
<style lang="less" scoped>
.text-btn + .text-btn {
  margin-left: 16px;
}
.danger {
  color: #ff4d4f;
}
.select {
  width: 100%;
}
.editName {
  // margin-top: 200px;
  width: 50%;
  // background-color: pink;
  // /deep/.ant-select {
  //   width: 90%;
  // }
}
.deletStyle {
  color: red;
  margin-left: 10px;
}
.compName {
  margin-left: 10px;
}
/deep/.modal_content {
  padding-top: 100px !important;
}
</style>
